import { BuilderComponent, BuilderContent } from '@builder.io/react'
import { getCognitoId, getVisitorId } from 'components/util/UserId'
import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PageLayout from '../../components/ui-builder/layouts/PageLayout'

import '../../builder-settings'

const defaultDescription = 'Recipe Description'

const defaultTitle = 'Recipe Title'

function RecipeTemplate({ data }: any) {
  const [_visitorId, setVisitorId] = useState('')
  const [_cognitoId, setCognitoId] = useState('')
  const content = data?.allBuilderModels.recipe[0]?.content
  const contentData = content.data
  const headerWrapperBackgroundColor = data?.allBuilderModels.recipe[0]?.data.headerWrapperBackgroundColor

  console.log('Recipe Page Template - recipes', data?.allBuilderModels.recipe)

  useEffect(() => {
    const callIdsForGtag = async () => {
      setVisitorId(await getVisitorId())
      setCognitoId(await getCognitoId())
    }
    callIdsForGtag()
  }, [])

  return (
    <BuilderContent model="recipe" content={content}>
      {recipe => {
        const recipeContent = recipe

        // console.log('RecipeTemplate:: Template on change data', recipeContent)
        return (
          <PageLayout
            headerIdentifier={recipeContent?.header?.id || contentData?.header?.id}
            footerIdentifier={recipeContent?.footer?.id || contentData?.footer?.id}
            bannerEnable={recipeContent?.enableTopBanner || contentData?.enableTopBanner}
            activeBannerIdentifier={recipeContent?.activeBanner?.id || contentData?.activeBanner?.id}
            title={recipeContent?.title || contentData?.ogTitle || defaultTitle}
            headerWrapperBackgroundColor={headerWrapperBackgroundColor}
            adultOnly={recipeContent?.adultOnly}
          >
            <Helmet>
              {/* meta tags for page */}
              <title>{recipeContent?.title || contentData?.ogTitle || defaultTitle}</title>
              <meta
                property="og:description"
                content={contentData?.ogDescription || recipeContent?.description || defaultDescription}
              />
              <meta
                name="description"
                content={recipeContent?.description || contentData?.ogDescription || defaultDescription}
              />
              <meta property="og:site_name" content={contentData?.siteName || 'Different Dog'} />
              <meta property="og:title" content={contentData?.ogTitle || recipeContent?.title || defaultTitle} />
              <meta property="og:type" content={contentData?.ogType || 'website'} />
              <meta
                property="og:url"
                content={
                  (contentData?.ogUrl || recipeContent?.canonical || content?.data?.url)?.substr(0, 1) === '/'
                    ? (contentData?.ogUrl || recipeContent?.canonical || content?.data?.url).replace(
                        '/',
                        'https://www.differentdog.com/'
                      )
                    : contentData?.ogUrl || recipeContent?.canonical || content?.data?.url
                }
              />
              contentData?.ogImage &&
              <meta property="og:image" content={contentData?.ogImage} />
              {recipeContent.noIndex ? <meta name="robots" content="noindex" /> : ''}
              <link
                rel="canonical"
                href={
                  (recipeContent?.canonical || content?.data?.url)?.substr(0, 1) === '/'
                    ? (recipeContent?.canonical || content?.data?.url).replace('/', 'https://www.differentdog.com/')
                    : recipeContent?.canonical || content?.data.url
                }
              />
              {/* meta tags ends for page */}
              {/* <!-- TrustBox script --> */}
              <script
                type="text/javascript"
                src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                async
              ></script>
              {/* <!-- End TrustBox script --> */}
              {/* <!-- Gtag script --> */}
              <script>{`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
              `}</script>
              {/* <!-- End Gtag script --> */}
              {/* Plausible Analytics script */}
              {/* <script defer data-api="/api/event" data-domain="differentdog.com" src="/js/script.js"></script> */}
            </Helmet>
            {/* <h2>Recipe template</h2> */}
            {/** name of the model is landing page, change it if you decided to build*/}
            <BuilderComponent model="recipe" content={content} />
          </PageLayout>
        )
      }}
    </BuilderContent>
  )
}

export default RecipeTemplate

export const RecipeTemplateQuery = graphql`
  query ($path: String!) {
    allBuilderModels {
      recipe(target: { urlPath: $path }, options: { cachebust: true }) {
        content
        data {
          headerWrapperBackgroundColor
        }
      }
    }
  }
`
